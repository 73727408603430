import React from "react";
import styled from "styled-components";
import Logo from "../assets/daw/daw-logo.png";
import {Link} from "react-router-dom"
import Account from "./Account";
import CurrentSaleStage from "../components/CurrentSaleStage";

const Wrap = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1C1C1C;
  @media(max-width: 765px) {
    justify-content: center;
    flex-direction: column;
  }
`;

const ImgWarp = styled.img`
  grid-column: 2;
  width: 10%;
  @media(max-width: 765px) {
    margin-bottom: 1rem;
  }
`;

const LogoWrap = styled.div`
  text-align: left;
  @media(max-width: 765px) {
    text-align: center; 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`

const ConnectWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: 765px) {
    flex-direction: column;
  }
`

const Home = styled.a`
  color: white;
  font-weight: 300;
  text-transform: uppercase;
  margin-left: 2rem;
  font-size: 1rem;
  &:hover {
    color: #03BAAD;
  }
  @media(max-width: 765px) {
    margin: 0;
  }
`

const Presale = styled.span`
  color: #03BAAD;
  font-weight: 300;
  margin-left: 2rem;
  font-size: 1.4rem;
  @media(max-width: 765px) {
    margin-left: 0;
    margin-bottom: 1rem;
  }
`

const ViewCollection = styled.a`
  color: white;
  text-transform: uppercase;
  cursor: not-allowed;
  font-size: 1rem;
  @media(max-width: 765px) {
    margin-bottom: 1rem;
  }
`

export default function Header(
  {
    address,
    localProvider,
    userSigner,
    mainnetProvider,
    web3Modal,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    blockExplorer
  }
) {
  return (
    <Wrap>
      <LogoWrap>
        <Link to="/">
          <ImgWarp src={Logo} alt="DAW Logo" />
        </Link>
        <Home href="https://desperateapewives.com/">
          Home
        </Home>
        <Presale>
          <CurrentSaleStage localProvider={localProvider} />
        </Presale>
      </LogoWrap>
      <ConnectWrap>
        <ViewCollection>
          View Your Collection
        </ViewCollection>
        <Account
          address={address}
          localProvider={localProvider}
          userSigner={userSigner}
          mainnetProvider={mainnetProvider}
          web3Modal={web3Modal}
          loadWeb3Modal={loadWeb3Modal}
          logoutOfWeb3Modal={logoutOfWeb3Modal}
          blockExplorer={blockExplorer}
        />
      </ConnectWrap>
    </Wrap>
  );
}
