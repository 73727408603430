import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { ethers } from "ethers";
import CountDownTimer from "../components/CountDownTimer";
import { useContractReader, useOnBlock } from "../hooks";
// import ContentLoader from "react-content-loader"
import DAWPreview from "../assets/daw/daw-preview.png"
import EthLogo from "../assets/daw/ethereum-logo.png"
import DiscordLogo from "../assets/daw/discord-logo.png"
import InstagramLogo from "../assets/daw/instragram-logo.png"
import TwitterLogo from "../assets/daw/twitter-logo.png"
import Logo from "../assets/daw/daw-logo-footer.png"
import CountUp from 'react-countup';

const DawPreviewWrap = styled.div`
  background: url(${DAWPreview});
  background-size: cover;
  width: 100%;
  height: 47vw;
  @media (max-width: 764px) {
    display: none;
  }
`

const SocialWrap = styled.div`
  @media(max-width: 765px) {
    width: 100%;
  }
`

const SocialImg = styled.img`
  width: 15%;
  @media(max-width: 765px) {
    width: 12%;
  }
`

const PriceWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const EthLogoImg = styled.img`
  margin-right: 1rem;
`

// need to update this so that its on render like use effect
const isMobile = () => {
  return window.innerWidth <= 700;
};

const Layout = styled.div`
  display: grid;
  position: relative;
  background: black;
  grid-template-columns: 1fr 1fr;
  background-size: cover;
  align-items: center;
  @media (max-width: 764px) {
    grid-template-columns: 1fr;
  }
`;

const CounterInput = styled.input`
  background: transparent;
  border: solid white 2px;
  text-align: center;
  width: 10%;
  margin-right: 2rem;
  margin-left: 2rem;
  font-size: 1rem;
  padding: 1rem;
  @media (max-width: 764px) {
    width: 30%;
  }
`

const CounterBtnWrap = styled.button`
  border: 0;
  background: transparent;
  color: white;
  font-size: 4vw;
  cursor: pointer;
  @media (max-width: 764px) {
    font-size: 10vw;
  }
  ${props => props.left && css`
     
  `}
`

const AmountWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Welcome = styled.div`
  text-transform: uppercase;
  font-size: 2vw;
  @media (max-width: 764px) {
    font-size: 5vw;
  }
`;

const MaxPerWallet = styled.div`
  color: rgb(255 255 255 / 60%);
  font-size: 1.1rem;
  @media (max-width: 764px) {
    font-size: 4vw;
  }
`

const PriceText = styled.div`
  font-size: 2vw;
  @media (max-width: 764px) {
    font-size: 4vw;
  }
`;

const MintBtn = styled.button`
  margin: 2rem 0;
  background: #03BAAD !important;
  color: #0F0F0F !important;
  padding: 0.5rem 1.7rem !important;
  cursor: pointer;
  text-transform: uppercase;
  justify-self: self-start;
  border: 0 !important;
  font-size: 1.5vw;
  transition: all .2s;
  &:hover {
    color: #03BAAD !important;
    background: #161616 !important;
    transition: all .2s;
  }
`;

const Nav = styled.div`
  padding: 5rem;
`

const NavWrap = styled.div`
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: black;
  text-transform: uppercase;
  font-weight: 600;
  border: solid white 2px;
  @media (max-width: 764px) {
    height: 600px;
    padding: 3rem;
    flex-direction: column;
  }
`;

const NavItem = styled.a`
  background: #03baad;
  font-size: 1.2vw;
  padding: 0.5vw 3vw;
  color: black;
  &:hover {
    color: white;
  }
  @media (max-width: 764px) {
    font-size: 3.2vw;
    padding: 0.5vw 3vw;
    width: 150px;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const FooterSection = styled.div`
  padding: 2rem;
  background: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media(max-width: 765px){
    flex-direction: column;
  }
`;

const CollectionWrap = styled.div`
  padding: 3rem;
`;

const CollectionHeading = styled.h2`
  margin-top: 1.5rem;
  font-size: 2.5vw;
  text-transform: uppercase;
  color: white;
  @media (max-width: 764px) {
    font-size: 5.5vw;
  }
`;

const CollectionListWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 3rem;
  margin-top: 2rem;
  @media (max-width: 765px) {
    grid-template-columns: 1fr;
  }
`;

const CollectionImg = styled.img`
  width: 65%;
  border-radius: 1rem;
`;

const CollectionItem = styled.div``;

const CollectionTitle = styled.div`
  font-size: 1vw;
  margin-top: 2rem;
  color: white;
  @media (max-width: 765px) {
    font-size: 3vw;
  }
`;

const CounterWrap = styled.div``;

const SoldFont = styled.div`
  font-size: 1.4rem;
  @media (max-width: 765px) {
    font-size: 2rem;
  }
`;

const MiddleSection = styled.div`
  color: white;
  padding: 4rem;
  display: grid;
  height: 100%;
`;

const mintPrice = 0.08;

const GridItem = styled.div`
  align-self: center;
  @media(max-width: 765px) {
    margin-bottom: 2rem;
  }
`

const CopywriteText = styled.div`
  color: white;
  text-align: left;
  @media(max-width: 765px) {
    margin-bottom: 1rem;
  }
`

const FooterLogo = styled.img`
  width: 100%;
`

const FooterLogoLink = styled.a`
  width: 10%;
  @media(max-width: 765px) {
    width: 50%;
    margin-bottom: 1rem;
  }
`

const SocialLink = styled.a`
  margin-left: 1rem;
`

const LoadingFont = styled.div`
  font-size: 1.5vw;
`

export default function DAW({
  address,
  localProvider,
  userSigner,
  mainnetProvider,
  price,
  web3Modal,
  loadWeb3Modal,
  logoutOfWeb3Modal,
  blockExplorer,
  readContracts,
  tx,
  writeContracts,
  getFromIPFS,
}) {
  const [currentTokens, setCurrentTokens] = useState(0);
  const [yourCollectibles, setYourCollectibles] = useState();
  const [isLoading, setLoading] = useState(true);
  const [collectionLoading, setCollectionLoading] = useState(true);
  const [purchaseCounter, setPurchaseCounter] = useState(1);
  const [priceWithCounter, setPriceWithCounter] = useState(mintPrice);

  const maxTokens = 10000;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showStats = () => {
    if(currentTokens){
      return (
        <>
          <CountUp end={currentTokens} duration={1} />
          <span>{`/${maxTokens} Sold`}</span>
        </>
      )
    }
  };


  const balance = useContractReader(readContracts, "DesperateApeWives", "balanceOf", [address]);

  const yourBalance = balance && balance.toNumber && balance.toNumber();

  // const totalMintSupply = useEventListener(readContracts, "DesperateApeWives", "Mint", localProvider)

  // useEffect(() => {
  //   if(totalMintSupply.length > 0){
  //     const { totalSupply } = totalMintSupply[0]
  //     console.log({totalSupply})
  //     setCurrentTokens(totalSupply.toNumber())
  //     setLoading(false)
  //   } else {
  //
  //   }
  // }, [totalMintSupply])

  useOnBlock(localProvider, async () => {
    if(await readContracts?.DesperateApeWives){
      const mintedTokens = (await readContracts.DesperateApeWives.totalSupply()).toNumber();
      setCurrentTokens(mintedTokens);
      console.log("minted tokens", mintedTokens);
      setLoading(false);
    }
  });

  /*
  useEffect(() => {
    setCollectionLoading(true);
    const updateYourCollectibles = async () => {
      const collectibleUpdate = [];
      for (let tokenIndex = 0; tokenIndex < yourBalance; tokenIndex++) {
        try {
          console.log("Getting token index", tokenIndex);
          const tokenId = await readContracts.DesperateApeWives.tokenOfOwnerByIndex(address, tokenIndex);
          console.log("tokenId", tokenId);
          const tokenURI = await readContracts.DesperateApeWives.tokenURI(tokenId);
          console.log("tokenURI", tokenURI);

          const ipfsHash = tokenURI.replace("ipfs://", "");
          console.log("ipfsHash", ipfsHash);

          const jsonManifestBuffer = await getFromIPFS(ipfsHash);
          const obj = JSON.parse(jsonManifestBuffer.toString());
          console.log({ obj });
          try {
            const jsonManifest = JSON.parse(jsonManifestBuffer.toString());
            console.log("jsonManifest", jsonManifest);
            collectibleUpdate.push({
              id: tokenId,
              source: 'https://ipfs.io/ipfs/QmX78zbR6p8EH3wyn4ocucsPEqKLKrvXoLJZVkhpCLmPwx/limozine2.mp4',
              // imageWithPath: obj.image.replace("ipfs://", "https://ipfs.io/ipfs/"),
              owner: address,
              ...jsonManifest,
            });
          } catch (e) {
            console.log(e);
          }
        } catch (e) {
          console.log(e);
        }
      }
      setYourCollectibles(collectibleUpdate);
      setCollectionLoading(false);
    };
    updateYourCollectibles();
  }, [address, yourBalance, balance, getFromIPFS]);

    const showCollectionSkeleton = (userBalance) => {
      console.log("your token balance =>", {userBalance})
      const array = []
      for(let i = 0; i < userBalance; i++){
        array.push(
        <ContentLoader
          speed={2}
          width={400}
          height={400}
          viewBox="0 0 400 400"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="2" rx="2" ry="2" width="400" height="400" />
        </ContentLoader>
        )
      }
      return array;
    }

    // <CollectionImg src={item.imageWithPath} alt={item.name} />
    const showNFTs = () => {
      if(yourCollectibles){
        return yourCollectibles.map(item => {
          const itemId = item.id.toNumber();
          console.log({ itemId });
          return (
            <CollectionItem key={itemId}>
              <a
              target="_blank"
              href={`https://opensea.io/assets/{verifiedContractAddress}/${itemId}`}
              rel="noopener noreferrer"
            >
              <video loop="" controls="" autoPlay="" name="media">
              <source src={item.source}
                      type="video/mp4" />
              </video>
                <CollectionTitle>{item.name}</CollectionTitle>
            </a>
            </CollectionItem>
        );
        })
      }
      return true;
    }
  */

  const handlePurchaseInput = (e) => {
    const newValue = e.target.value;
    if(newValue <= 3){
      console.log('new purchase price', newValue);
      setPurchaseCounter(newValue);
      setPriceWithCounter(newValue * mintPrice);
    }
  };

  const handleDecrease = () => {
    if (purchaseCounter > 1) {
      const newCount = purchaseCounter - 1;
      setPurchaseCounter(newCount);
      setPriceWithCounter(newCount * mintPrice);
    }
  };

  const handleIncrease = () => {
    if (purchaseCounter < 3) {
      const newCount = purchaseCounter + 1;
      setPurchaseCounter(newCount);
      setPriceWithCounter(newCount * mintPrice);
    }
  };

  return (
    <>
      <Layout isMobile={isMobile()}>
        <DawPreviewWrap />
        <MiddleSection>
          <GridItem>
            <Welcome>We Are Launching</Welcome>
            <Welcome>The Desperate Apewives</Welcome>
            {/* Only show during public sale*/}
            <MaxPerWallet>3 Max Per Wallet</MaxPerWallet>
          </GridItem>
          <GridItem>
            <PriceWrap>
              <EthLogoImg src={EthLogo} />
              <PriceText>{priceWithCounter} ETH</PriceText>
            </PriceWrap>
            <AmountWrap>
              <CounterBtnWrap onClick={handleDecrease}>
                -
               </CounterBtnWrap>
              <CounterInput value={purchaseCounter} onChange={handlePurchaseInput} />
              <CounterBtnWrap onClick={handleIncrease}>
                +
              </CounterBtnWrap>
            </AmountWrap>
            <CounterWrap>
              <MintBtn
                onClick={async () => {
                  console.log("ETH Amount >", (mintPrice * purchaseCounter).toString());
                if (userSigner) {
                tx(
                  writeContracts.DesperateApeWives.mintWife(purchaseCounter, {
                  value: ethers.utils.parseUnits((mintPrice * purchaseCounter).toString()),
                  }),
                );
                  } else alert("You forgot to connect your wallet");
                }}
                >
                Mint Now
              </MintBtn>
            </CounterWrap>
          </GridItem>
          <GridItem>
            <CountDownTimer localProvider={localProvider} />
            {/*<div>Pre-Sale Ends In: <span style={{color: '#03BAAD'}}>10:23:32</span></div>*/}
            <SoldFont>Sold Out</SoldFont>
            {/*{!isLoading ? <SoldFont>{showStats()}</SoldFont> : <LoadingFont>Loading...</LoadingFont>}*/}
          </GridItem>
        </MiddleSection>
      </Layout>
        {/*<CollectionWrap>*/}
        {/*  <CollectionHeading>Your Collection</CollectionHeading>*/}
        {/*  <CollectionListWrap>*/}
        {/*    {collectionLoading ? showCollectionSkeleton(yourBalance) : showNFTs()}*/}
        {/*  </CollectionListWrap>*/}
        {/*</CollectionWrap>*/}
      {/* Navigation */}
      <Nav>
        <NavWrap>
            <NavItem target="_blank" href="https://twitter.com/DApeWives">
              Twitter
            </NavItem>
            <NavItem target="_blank" href="https://discord.com/invite/nXCbs99BfG">
              Discord
            </NavItem>
            <NavItem target="_blank" href="https://opensea.io/collection/desperate-ape-wives">
              OpenSea
            </NavItem>
            <NavItem target="_blank" href="https://etherscan.io/address/0xf1268733c6fb05ef6be9cf23d24436dcd6e0b35e">
              Verified Contract
            </NavItem>
          </NavWrap>
      </Nav>
      <FooterSection>
        <CopywriteText>
          <div>DAW Terms & Conditions</div>
          <div>&copy; 2021 Desperate ApeWives</div>
        </CopywriteText>
        <FooterLogoLink href="https://desperateapewives.com" target="_blank" rel="noreferrer">
          <FooterLogo src={Logo} />
        </FooterLogoLink>
        <SocialWrap>
          <SocialLink href="https://discord.com/invite/nXCbs99BfG" target="_blank" rel="noreferrer">
            <SocialImg src={DiscordLogo} alt="discord logo"/>
          </SocialLink>
          <SocialLink href="https://twitter.com/DApeWives" target="_blank" rel="noreferrer">
            <SocialImg src={TwitterLogo} alt="twitter logo"/>
          </SocialLink>
          <SocialLink href="https://www.instagram.com/desperateapewives/" target="_blank" rel="noreferrer">
            <SocialImg src={InstagramLogo} alt="instagram logo"/>
          </SocialLink>
        </SocialWrap>
      </FooterSection>
    </>
  );
}
