import React, { useState } from "react";
import styled from "styled-components";
import { useContractLoader, useOnBlock, usePoller } from "../hooks";

const CountdownWrap = styled.span`
  text-align: center;
  font-size: 1.2vw;
  @media (max-width: 745px) {
    font-size: 4vw;
  }
`;

export default function CurrentSaleStage({ localProvider }) {
  const [countdownTimes, setCountdownTimes] = useState([]);
  const readContracts = useContractLoader(localProvider);
  const [currentTime, setCurrentTime] = useState();

  usePoller(() => {
    setCurrentTime(Math.floor(Date.now() / 1000));
  }, 1000);

  useOnBlock(localProvider, async () => {
    if(readContracts?.DesperateApeWives) {
      const bnSaleTimes = await readContracts.DesperateApeWives.getSaleTimes();
      const saleTimes = bnSaleTimes.map(time => time.toNumber());
      setCountdownTimes(saleTimes);
      setCurrentTime(Math.floor(Date.now() / 1000));
      console.log({ saleTimes });
    }
    return true;
  });

  function renderCountdown() {
    if (currentTime > countdownTimes[2]) {
      return 'Public Sale is Live!';
    }
    if (currentTime > countdownTimes[1]) {
      return 'Pre-Sale Ended!';
    }
    if (currentTime > countdownTimes[0]) {
      return 'Pre-Sale Started!';
    }
  }

  return <CountdownWrap>{renderCountdown()}</CountdownWrap>;
}
