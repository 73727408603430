import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import Plausible from 'plausible-tracker'

const { enableAutoPageviews } = Plausible({
  domain: 'mint.desperateapewives.com'
})

enableAutoPageviews();

Sentry.init({
  dsn: "https://2c9573562c254131a30a0bd1f3d413fe@o1014246.ingest.sentry.io/6035799",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
    <App />
, document.getElementById("root"));
